.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  overflow-y:hidden;
  font-family: 'Open Sans' !important;
  color:"#333333" !important;
  }
  
h1, h2, h3 {
  font-family: 'Open Sans' !important;
  color:"#333333";
}
/* Your Journey css */
 * {
   box-sizing: border-box;
 }

 .timeline {
   position: relative;
   width: 100%;

   margin: 0 auto;
   padding: 15px 0;
 }

 .timeline::after {
   content: '';
   position: absolute;
   width: 3px;
   background: grey;
   top: 0;
   bottom: 0;
   left: 50%;
   margin-left: -5px;
 }

 /* The circles on the timeline */
 .tl_circle::after {
   content: '';
   position: absolute;
   width: 20px;
   height: 20px;
   background-color: white;
   border: 3px solid gray;
   top: 0px;
   border-radius: 50%;
   z-index: 1;
   left: 20px;
 }

/* Sign up page css */

.signUp{
  background-image: url(./Images/landing-page-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color:"#006fde";
   color:"white";
}
.bg-img{
  background-image: url(./Images/landing-page-bg.jpg);
  background-repeat: no-repeat;
  /* overflow-x:hidden;
  overflow-y:hidden; */
  background-size: cover;
  background-attachment: fixed; 
  margin-left: 0px !important;
  margin-right:0px !important;
  /* background-color: #cee2fa; */

}
 .box{
  margin:auto;

  padding:0;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #ffffff;

   width: 600px;
   padding: 20px;
   height:90vh;
   overflow:auto;
    /* position:static;  */
    /* position: absolute;  */
   /* top: 25%;
   left: 25%;   */
   text-align: center;
   border-radius: 12px;
  


 }
 button{
  background-color:"#006fde" !important;
  border-color:"#006fde";
 }

a{
  border-color:"#006fde";
}

/*
 ::-webkit-scrollbar {
  width: 12px;
}
*/
::-webkit-scrollbar-track {
  border-radius: 10px;
}
/*
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
*/
 /* .box{
  margin:auto;
  padding:0;
  font-family:sans-serif;
  background-color: #ffffff;
   width: 600px;
   padding: 20px;
    position: absolute; 
    top: 50%;
   left: 50%; 
   text-align: center;
   border-radius: 12px;
 } */

 .form-control{
  /* border:10px; */
  /* border-color: #a00a48 !important; */
  display:block;
  margin: 10px auto;
  text-align: left;
 }

 .signupBtn{
   border-radius: 50px !important;
   height: 50px;
   display: block;
   border:2px;
   margin: 20px auto;
   background-color: #007eff !important;
   color: white !important;


 }
 /* .signupBtn:hover{
   background-color: #fdd70d !important;
   color:#333333 !important;
 } */

 .userBtn{
   border:1px solid;
   border-color: #006fde;
   background-color:  #cee3f9;
   color:#000000 ;
   margin: auto 20px;
   padding: 10px 30px;
   border-radius: 30px;
   display:inline;
 }
 .therapistBtn{
   border: 1px solid;
   border-color: #fdd70d;
   color: #333333;
   background-color:#fdf3c0 ;
   margin: auto 20px;
   padding: 10px 30px;
   border-radius: 30px;
   display:inline;
 }

.userBtn:focus{
  background-color: #006fde;
  color:white;
}
.userBtn:hover{
  background-color: #006fde;
  color:white;
}
.therapistBtn:focus{
  background-color: #ffd70d;
  color:#333333

}
.therapistBtn:hover{
  background-color: #ffd70d;
  color:#333333
}

/* ul.sidepane>li a{
  padding:10px;
  height:40px;
} */


ul.sidebar > li a {
  margin-left: 0px;
    font-family:sans-serif;
    /* background-color: blue; */
    width:250px;
    height:40px;
     /* padding: 10px 35px; */
     
    color:#333 !important;
  
  }
  ul.sidebar > li a:hover {
    margin-left: 0px;
      font-family:sans-serif;
      background-color: #e7e7e7;
      width:250px;
      height:40px;
       /* padding: 10px 35px; */
      color:#333 !important;
      cursor:pointer;
    
    }
  .listItem {
    cursor:pointer;
    margin:0px;
    padding:10px;
    width:330px;
    word-break: break-word;
    text-align: left;
    font-size: 18px;

  }

  
  .playAudioBtn {
    font-size: 80px;
    color: #004d99;
  }
  
  .audioControlBtn {
    font-size: 50px;
    color: grey;
    margin: 0;
    padding: 0;
  }
  
  .controlSeconds{
    line-height: 10px;
    color: grey;
  }
  
  .languageBtn {
    background-color: #a9d8ff !important;
    border-radius: 0px;
  }
    
  .overlay{
  position: fixed; 
  display: none; 
  width: 100%; 
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  z-index: 2; 
  cursor: pointer; 
  }

  table, tr, th, td {
    border:none !important;
    padding:0! important;
    margin:0 !important;
  }
  table
{
  border-collapse:collapse;
}
